import React from 'react';
import {Page, Text} from "@geist-ui/react";

const HubList = (props) => {
    return (
        <Page>
            <Text h1>401</Text>
            <Text h3>This space is private for now.</Text>
        </Page>
    );
}

export default HubList;
