import './App.css';
import React, {useRef, useState} from "react";
import {Frame} from "./frame/Frame";
import {Menu} from "@geist-ui/react-icons";
import {Sidebar} from "./frame/Sidebar";

export const App = (props) => {
    const [url, _setURL] = useState("");
    const sidebar = useRef(null);

    const setURL = (url) => {
        _setURL(url);
        sidebar.current.classList.remove("isOpen");
    }

    return (
      <>
          <div ref={sidebar} className="sideBarWrapper isOpen">
              <Sidebar setUrl={setURL}/>
              <div onClick={() => sidebar.current.classList.toggle('isOpen')}  className="sideBarOpener">
                  <Menu/>
              </div>
          </div>
          <Frame url={url}/>
      </>
    );
}

export default App;
