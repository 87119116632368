import React, {useRef} from 'react';
import {Button, ButtonGroup, Card, Grid, Input, Spacer, Text, useInput, User} from "@geist-ui/react";
import {Heart, RotateCw} from "@geist-ui/react-icons";

const TopBar = (props) => {
    const linkInput = useRef(null);

    return (
        <Card>
            <Card.Content style={{paddingTop: "0.5em", paddingBottom: "0.5em"}}>
            <Grid.Container gap={1} justify="space-between" alignItems="center">
                <ButtonGroup type="success-light">
                    <Button icon={<RotateCw />} onClick={props.reload}>Neu laden</Button>
                </ButtonGroup>
                <div width="60%">
                    <Input ref={linkInput} status="success" placeholder="direkt eine URL eingeben"/>
                    <Button onClick={() => {if (linkInput && linkInput.current.value) props.setUrl(linkInput.current.value)}} auto>Los!</Button>
                </div>
                <User src="https://avatars.githubusercontent.com/u/32138575?s=400&u=f48fab5bdfcb7fb1d27ec4f2748a82bc9d3c5d2e&v=4" name={<Text>Made with <Heart size={12}/> by Jonas Bernard</Text>}/>
            </Grid.Container></Card.Content>
        </Card>
    );
}

export default TopBar;
