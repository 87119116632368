import React from 'react';
import {Button, useToasts} from "@geist-ui/react";
import {Plus} from "@geist-ui/react-icons";

export const AddRoom = (props) => {
    const CREATE_ROOM_URL = process.env.REACT_APP_REST_URL+"/api/v1/room/new";

    const [, setToast] = useToasts();

    const error = (message) => {
        setToast({text: message, type:"error"});
    }

    const trim = (string) => {
        return string.replace(/^\s+/, '').replace(/\s+$/, '');
    }

    const validURL = (str) => {
        let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return pattern.test(str);
    }

    const createRoom = (name, url, success, error) => {
        fetch(CREATE_ROOM_URL, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({name: name, url: url, hub_id: props.hub_id, parent: props.parent})})
            .then((result) => {if (result.ok){success(result.json())}else{error("Status Code " + result.status)}}, error)
            .catch(error);
    }

    const addRoom = () => {
        const n = props.nextRoomName();
        const u = props.url;

        if (trim(n) === "") {
            error("[5] Der Name darf nicht leer sein.");
            return;
        }
        if (trim(u) === "") {
            error("[4] Der Link darf nicht leer sein.");
            return;
        }
        if (props.checkURLSchema && !validURL(u)) {
            error("[3] Bitte gib einen gültigen Link ein.");
            return;
        }

        createRoom(n,u, (result) => {
            setToast({text: "Erfolgreich hinzugefügt", type: "success"});
            props.success();
        }, (error) => {
            setToast({text: "[2] Ein Fehler ist aufgetreten: " + error, type: "error"});
        });
    }

    return (
        <Button disabed={props.disabled} auto size="small"
                style={{ background: "#11BF11", color: "white", border: "1px solid white" }}
                icon={<Plus color="white" />}
                onClick={() => addRoom()}>
            Raum hinzufügen
        </Button>
    )
}
