import React from 'react';

export const Frame = (props) => {
    if (props.url) { return (
        <iframe title="mainGameHubFrame"
                frameBorder="0" scrolling="yes"
                className="mainFrame"
                src={props.url}>
            <span>Noch kein Spiel geöffnet.</span>
        </iframe>
    ); } else { return (
        <span className="openHint">Spiel auswählen.</span>
    ) }
}
