import './GameInfo.css'
import React, {useRef, useState} from 'react';
import {
    Button,
    Card,
    Container,
    Grid,
    Input,
    Modal, Note,
    Snippet,
    Spacer,
    Table, Text, useClipboard,
    useModal,
    useToasts
} from "@geist-ui/react";
import {Trash2} from '@geist-ui/react-icons';
import {AddRoom} from "./AddRoom";

const GameInfo = (props) => {
    const sudoMode = false;

    const { deleteModalVisible, setVisible, bindings } = useModal();
    const [room, selectRoom] = useState({});
    const [,setToast] = useToasts();
    const linkInput = useRef(null);
    const [currLink, setLink] = useState("");
    const copy = useClipboard();

    const DELETE_ROOM_URL = process.env.REACT_APP_REST_URL+"/api/v1/room/<ROOM_ID>";


    const deleteCurrentRoom = () => {
        if (!room.id && room.id !== 0) {
            setToast({ text: '[12] Raum konnte nicht gelöscht werden, da kein Raum ausgewählt ist.', type: "secondary"});
            return;
        }
        fetch(DELETE_ROOM_URL.replace('<ROOM_ID>', room.id.toString()),
            {method: 'DELETE'})
            .then((result) => {if (result.ok){
                setToast({ text: "Raum erfolgreich gelöscht.", type: 'success'});
                props.fetchRooms();
            }else{
                setToast({ text: `[10] Raum konnte nicht gelöscht werden. (Status ${result.status})`, type: 'error'})
            }}, (error) => {
                setToast({ text: `[11] Raum konnte nicht gelöscht werden. (${error.toString()})`, type: 'error'})
            });
        selectRoom({});
    };

    const setURL = () => {
        props.setUrl(props.game.url.toString());
    }

    const join = (rowData) => {
        props.setUrl(rowData.rowValue.url.props.text.toString());
    }

    const joinAction = (actions, rowData) => {
        return props.game.onlyURLsAllowed ?
            <Button type="success" auto size="small" onClick={() => join(rowData)}>Mitspielen</Button> :
            <Button type="success" auto size="small" onClick={() => {
                copy(rowData.rowValue.url.props.text.toString());
                setToast({ text: 'Raumkennung kopiert.', type: 'success' })
            }}>Kopieren</Button>
    }

    const deleteAction = (actions, rowData) => {
        return <Button disabled={!props.game.canUserAddRooms} type="error-light" auto size="small" iconRight={<Trash2 />} onClick={() => {selectRoom(rowData.rowValue); setVisible(true)}}/>
    }

    const nextRoomName = () => {
        let i = 1;
        let name = props.game.name + " " + (props.rooms.length + i)
        const names = props.rooms.map(r => r.name);
        while (names.includes(name)) {
            name =  props.game.name + " " + (props.rooms.length + i++)
        }
        return name;
    }

    const getTableData = () => {
        let rooms = props.rooms.map(room => {return {
            deleteAction: deleteAction,
            joinAction: joinAction,
            ...room,
            url: <Snippet symbol="" text={room.url} className="linkText" style={{maxWidth: "200px", width: "100%"}} />,
        }});

        if (props.game.canUserAddRooms || sudoMode) return [{
            name: "Hinzufügen",
            url: <Input ref={linkInput} status="success" placeholder={props.game.onlyURLsAllowed ? "Link zum Raum" : "Raumkennung"} className="linkText" style={{maxWidth: "200px", width: "100%"}}
                        onChange={(e) => {setLink(linkInput && linkInput.current && linkInput.current.value ? linkInput.current.value : "")}}/>,
            joinAction: <AddRoom disabed={props.disabled}
                                 hub_id={props.hub_id} success={props.fetchRooms}
                                 parent={props.game.name} nextRoomName={nextRoomName}
                                 url={currLink} checkURLSchema={props.game.onlyURLsAllowed}/>
        }].concat(rooms);
        else return rooms;
    }

    return (
        <>
            <Card hoverable>
                <Grid.Container gap={2} justify="space-between" alignItems="center">
                    <Grid xs={19}>
                        <Text h2>{props.game.name}</Text>
                    </Grid>
                    { props.game.canUserAddRooms || sudoMode ? <Grid xs={5}>
                        <Button auto size="small" type="success" onClick={setURL}>"Neues Spiel"</Button>
                    </Grid> : <></> }
                    <Grid xs={24}>
                        <Table data={getTableData()}>
                            <Table.Column prop="name" label="Name" />
                            <Table.Column prop="url" label={props.game.onlyURLsAllowed ? "URL" : "Raumkennung"} />
                            <Table.Column prop="joinAction" label="Mitspielen" width={50} />
                            <Table.Column prop="deleteAction" label="Löschen" width={50} />
                        </Table>
                    </Grid>
                </Grid.Container>
            </Card>
            <Modal {...bindings}>
                <Modal.Title>Löschen</Modal.Title>
                <Modal.Subtitle>Kann nicht rückgängig gemacht werden.</Modal.Subtitle>
                <Modal.Content>
                    <p>Willst du den Raum wirklich löschen?</p>
                </Modal.Content>
                <Modal.Action passive onClick={() => setVisible(false)}>Abbrechen</Modal.Action>
                <Modal.Action onClick={() => {setVisible(false); deleteCurrentRoom();}}>Löschen</Modal.Action>
            </Modal>
        </>
    );
}

export default GameInfo;
