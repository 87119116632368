import {Badge, Dot, Grid, Loading, Page, Spacer, useToasts} from "@geist-ui/react";
import React, {useDebugValue, useEffect, useState} from "react";
import {GameList} from "./GameList";
import GameInfo from "./GameInfo";
import TopBar from "./TopBar";
import {useFetchWithTimeout} from "../hooks/useFetchWithTimeout";

const games = [
    {name: "Alle Räume", url: "all", canUserAddRooms: false, onlyURLsAllowed: false},
    {name: "Andere Spiele", url: "custom", canUserAddRooms: true,  onlyURLsAllowed: false},
    {name: "Cards against Humanity (vor Mitternacht)", url: "https://not.allbad.cards/", canUserAddRooms: true, onlyURLsAllowed: true},
    {name: "Cards against Humanity", url: "https://allbad.cards/", canUserAddRooms: true, onlyURLsAllowed: true},
    {name: "Codenames", url: "https://codenames.game", canUserAddRooms: true, onlyURLsAllowed: true},
    {name: "Codenames (Netgames)", url: "https://netgames.io/games/codewords/", canUserAddRooms: true, onlyURLsAllowed: false},
    {name: "Doppelkopf", url: "doko", canUserAddRooms: false, onlyURLsAllowed: true},
    {name: "Drawbattle", url: "https://drawbattle.io/gather/YpwqNk8gjoTYbdKctable6", canUserAddRooms: true, onlyURLsAllowed: true},
    {name: "Gartic Phone", url: "https://garticphone.com/de", canUserAddRooms: true, onlyURLsAllowed: true},
    {name: "Just One", url: "https://just1.herokuapp.com/", canUserAddRooms: true, onlyURLsAllowed: false},
    {name: "Make it Meme", url: "https://makeitmeme.com/", canUserAddRooms: true, onlyURLsAllowed: true},
    {name: "Poker", url: "poker", canUserAddRooms: false, onlyURLsAllowed: true},
    {name: "Set", url: "https://setwithfriends.com", canUserAddRooms: true, onlyURLsAllowed: true},
    {name: "Skribble", url: "https://skribbl.io/", canUserAddRooms: true, onlyURLsAllowed: true},
    {name: "Siedler", url: "https://colonist.io/", canUserAddRooms: true, onlyURLsAllowed: true},
    //{name: "Dominion", url: "https://dominion.games", canUserAddRooms: true, onlyURLsAllowed: false},
    {name: "Werwolf (englisch)", url: "https://netgames.io/games/onu-werewolf/", canUserAddRooms: true,  onlyURLsAllowed: false},
];

export const Hub = (props) => {
    const [error, setErrorText] = useState('');
    const [game, selectGame] = useState({});
    const [visibleRooms, setVisibleRooms] = useState([]);
    const [,setToast] = useToasts();

    const HUB_URL = process.env.REACT_APP_REST_URL+"/api/v1/hub/"+props.match.params.hub_id;
    const [loading, roomsResult, fetchRooms] = useFetchWithTimeout(HUB_URL, {});

    useEffect(() => {
        if (!Array.isArray(roomsResult.rooms)) return;
        if (game.url === 'all') {
            setVisibleRooms(roomsResult.rooms);
        } else if (game.url === 'custom') {
            let possibleParents = games.map(game => game.name).filter(name => name !== 'Alle Räume' && name !== 'Andere Spiele');
            console.log(roomsResult.rooms);
            let candidat = roomsResult.rooms.filter(r => !possibleParents.includes(r.parent));
            setVisibleRooms(Array.isArray(candidat) ? candidat : []);
        } else
            setVisibleRooms(roomsResult.rooms.filter(r => r.parent === game.name));
    }, [roomsResult, game]);

    useEffect(fetchRooms, []);

    if (error) return (
        <Page><Grid.Container gap={2} justify="center"><Grid xs={24}><Dot type="error">{error}</Dot></Grid></Grid.Container></Page>
    ); else if (loading) return (
        <Page><Grid.Container gap={2} justify="center"><Grid xs={24}><Loading>Loading Room</Loading></Grid></Grid.Container></Page>
    ); else return (
        <Page>
            <Page.Content>
                <Grid.Container gap={1} justify="center">
                    <Grid xs={24}><TopBar disabed={Array.isArray(roomsResult.rooms)} reload={fetchRooms} hub_id={props.match.params.hub_id} setUrl={props.setUrl}/></Grid>
                    <Grid xs={24} md={8}><GameList games={games} selectGame={selectGame} isLoaded={Array.isArray(roomsResult.rooms)}/></Grid>
                    { game && game.url && game.name ? <Grid xs={24} md={16}><GameInfo game={game} rooms={visibleRooms} setUrl={props.setUrl} hub_id={roomsResult.id} fetchRooms={fetchRooms}/></Grid> : <></> }
                </Grid.Container>
            </Page.Content>
            <Page.Footer>
                <span><Badge>ID: {props.match.params.hub_id}</Badge></span>
                { game && game.name && game.url ? <><Spacer y={0.1} /><span><Badge>Spiel: {game.name} ({game.url})</Badge></span></> : <></>}
                <Spacer y={1} />
            </Page.Footer></Page>
    );
}
