import {useState} from 'react';
import {useToasts} from "@geist-ui/react";

export const useFetchWithTimeout = (URL, requestData) => {
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState({});
    const [,setToast] = useToasts();

    const retry = {
        name: 'Erneut versuchen',
        handler: () => timeout()
    }

    const fetchIt = () => {
        fetch(URL, requestData)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoading(false);
                    if (result.message) {
                        setToast({
                            text: result.message,
                            type: 'warning'
                        });
                    }
                    setResult(result);
                },
                (error) => {
                    setToast({
                        text: 'Die Verbindung zum Server ist fehlgeschlagen',
                        type: 'error',
                        actions: [retry]
                    });
                    console.log(error);
                    setTimeout(()=>timeout(),25000);
                }
            );
    }

    const timeout = () => {
        try {
            setIsLoading(true);
            const response = Promise.race([
                fetchIt(),
                new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 10000)),
            ]);
        } catch (e) {
            if (e.message === 'Timeout') {
                setToast({
                    text: 'Die Verbindung zum Server ist fehlgeschlagen',
                    type: 'warning',
                    actions: [retry]
                })
            } else {
                setToast({
                    text: 'Ein Fehler ist aufgetreten: ' + e.toString(),
                    type: 'error'
                });
            }
        }
    }

    return [isLoading, result, timeout];
}
