import React from 'react';
import {Hub} from "../hub/Hub";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import HubList from "../HubList";
import {CssBaseline, GeistProvider, Page, Text} from "@geist-ui/react";

export const Sidebar = (props) => {
    return (
        <GeistProvider>
            <CssBaseline />
            <Router>
                <Switch>
                    <Route exact path="/:hub_id(\d+)" render={(p) => <Hub {...p} setUrl={props.setUrl}/>}/>
                    <Route exact path="/" component={HubList} />
                    <Route path="/" render={() => <Page><Text h1>404</Text>
                        <Text h3>[404] This site was not found.</Text></Page>} />
                </Switch>
            </Router>
        </GeistProvider>
    );
}
