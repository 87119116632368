import './GameList.css';
import React from 'react';
import {Card, Display, Text} from "@geist-ui/react";

export const GameList = (props) => {
    const handler = (name) => {
        props.selectGame(props.games.find(game => game.name === name));
    };

    if (props.isLoaded) {
        if (props.games.length > 0) {
            return (
                <Card hoverable>
                    <Display caption="Wähle ein Spiel aus." style={{width: "100%"}}>
                        <div className="gameList">
                            {props.games.map((value, index) =>
                                <li key={value.name} value={value.url.toString()}>
                                    <a onClick={() => handler(value.name)}>{value.name}</a>
                                </li>
                            )}
                        </div>
                    </Display>
                </Card>
            );
        }
        return (
            <Display caption="[9] Dieser Hub enthält keine Spiel.">
                <Text h3>:(</Text>
            </Display>
        );
    } else { return (
        <Display caption="[7] Hub konnte nicht geladen werden.">
            <Text h3>Fehler</Text>
        </Display>
    )}
}
